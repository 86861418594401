import React, { Component } from "react";
import { firebase } from "@firebase/app";
export default class Contact extends Component {
  componentDidMount() {
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    const form = document.querySelector("#contact-us");
    e.preventDefault();
    console.log(form.firstName.value);
    console.log(form.lastName.value);
    console.log(form.email.value);
    console.log(form.phone.value);
    console.log(form.message.value);

    firebase
      .firestore()
      .collection("messages")
      .add({
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
        phone: form.phone.value,
        message: form.message.value,
      })
      .then(function () {
        alert("Information submitted Successfully");
        form.firstName.value = "";
        form.lastName.value = "";
        form.email.value = "";
        form.phone.value = "";
        form.message.value = "";
      })
      .catch(function (error) {
        alert(error);
        alert(
          "There was an issue submitting the information. call as at +254 742 060116 to talk to us directly"
        );
      });
  }
  render() {
    return (
      <div className="wrapper">
        <div className="form-wrapper">
          <form id={"contact-us"}>
            <div className="firstName">
              <label className="form-text" htmlFor="firstName">
                First Name
              </label>
              <input
                className={""}
                type="text"
                name="firstName"
                noValidate
                required={"yes"}
                minLength={3}
                maxLength={10}
              />
            </div>
            <div className="lastName">
              <label className="form-text" htmlFor="lastName">
                Last Name
              </label>
              <input
                className={""}
                type="text"
                name="lastName"
                noValidate
                required={"yes"}
                minLength={3}
                maxLength={10}
              />
            </div>
            <div className="email">
              <label className="form-text" htmlFor="email">
                Email
              </label>
              <input
                className={""}
                type="email"
                noValidate
                name="email"
                required={"yes"}
                minLength={3}
              />
            </div>
            <div className="email">
              <label className="form-text" htmlFor="tel">
                Phone
              </label>
              <input className={""} type="tel" required name="phone" />
            </div>
            <div className="message">
              <label className="form-text" htmlFor="text">
                How can we be of help?
              </label>
              <textarea
                className={""}
                type="text"
                name="message"
                rows={4}
                noValidate
              />
            </div>
            <div className="submit-but">
              <button
                type="button"
                class="btn btn-outline-primary "
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
                onClick={this.handleSubmit}
              >
                Send Message{" "}
              </button>
              <i class="fab fa-facebook"></i>{" "}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
