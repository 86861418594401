import React from "react";
import picA from "../pics/nutrition.jpg";
import picD from "../pics/massage.jpeg";
import picG from "../pics/phyc.jpeg";

const Services = () => {
  return (
    <div id="service" className="class-sec  container-fluid">
      <div id="about" className="about-sec row-pad class-sec container-fluid">
        <div className="container">
          <div className="row bodytitles  justify-content-center ">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
              <h1 className="titles"> Our Services</h1>
            </div>
          </div>
        </div>
        <div
          className="row  justify-content-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center text-center">
            <p className={"webtxt"}>
              {" "}
              At Bodyflex, we take pride in serving our customers. Our customers
              experience exceptional workouts and at the same time benefit from
              our top tier services within the same facility.
            </p>
          </div>
        </div>

        <div class="row justify-content-center">
          <i class="fas fa-dumbbell"></i>
        </div>
      </div>
      <div className="row justify-content-center top-pad">
        <div
          className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 justify-content-center "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <img alt="" className="class-img img-fluid " src={picA} />
        </div>
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center center-pad">
          <div className="col-12 justify-content-center">
            <h5 className="card-title text-left">Diet & Nutrition</h5>
            <p className="webtxt text-left">
              We offer nutritional programs to help you achieve your weightloss,
              contest preparation, bodybuilding and sport performance goals.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center top-pad">
        <div
          className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <img alt="" className=" class-img img-fluid " src={picD} />
        </div>
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center center-pad">
          <div className="col-12 justify-content-center ">
            <h5 className=" card-title  text-left">
              Facial & Massage Services
            </h5>
            <p className="webtxt text-left">
              Visit our center and recieve a healing and relaxation feeling
              through our massage program. In addition, we offer facial therapy
              that will leave your skin looking healthy, younger and truly
              transformed.
            </p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center top-pad">
        <div
          className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <img alt="" className="class-img img-fluid" src={picG} />
        </div>
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center center-pad">
          <div className="col-12 justify-content-center ">
            <h5 className=" card-title text-left ">Physiotherapy</h5>
            <p className="webtxt text-left">
              We offer cardiovascular conditioning, strength training ,
              therapeutic exercises designed to improve your mobility, reduce
              pain and reduce the risk of getting injuries in the future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
