import React from "react";
import Contact from "./Contact";
const Talk = () => {
  return (
    <div id="contact" className=" con-sec class-sec">
      <div className="container-fluid">
        <br></br>
        <div className="row  justify-content-center ">
          <div
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
            data-aos-once="true"
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center"
          >
            <h1 className=" titles text-center"> Contact Us </h1>
          </div>
        </div>
        <br />
        <div className="row  justify-content-center ">
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 d-flex justify-content-center">
            <h1 className="  card-titles text-center small-pad">
              {" "}
              Give Us A Call{" "}
            </h1>
            <br />
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-4 d-flex justify-content-center">
            <a href="tel:+254742060116">
              <svg
                viewBox="0 0 16 16"
                class="bi bi-telephone-outbound"
                fill="orange"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </a>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6 d-flex justify-content-center">
            <h1 className="   card-titles  text-center small-pad">
              {" "}
              Send Us An Email{" "}
            </h1>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-4 d-flex justify-content-center">
            <a href="mailto:bodyflexgymsltd@gmail.com">
              <svg
                viewBox="0 0 16 16"
                class="bi bi-envelope"
                fill="orange"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="row ">
          <div className="col-xl-6 col-lg-12  col-md-12  col-sm-12 col-xs-12 d-flex justify-content-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.600130847616!2d34.777414315258106!3d0.5992112636928721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17803c2381e6b747%3A0xd788ea540e29f427!2sMasinde%20Muliro%20University%20of%20Science%20and%20Technology!5e0!3m2!1sen!2sus!4v1601341533908!5m2!1sen!2sus"
              frameborder="0"
              styles="border:0;"
              aria-hidden="false"
              className="responsive-iframe location"
              title="location"
            ></iframe>
          </div>
          <div className="col-xl-6 col-lg-12  col-md-12  col-sm-12 col-xs-12 ">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Talk;
