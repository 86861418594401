import React from "react";
import picA from "../pics/equip-3.jpg";
import picB from "../pics/gb-5m.jpg";
import picD from "../pics/eq16.jpg";
import picF from "../pics/tred.jpg";
import picC from "../pics/equip-5.jpg";
import picG from "../pics/insanity.jpg";
import picE1 from "../pics/equip-4.jpg";
import picE2 from "../pics/eq6b.jpg";
import picE3 from "../pics/eq17.jpg";
import picE4 from "../pics/equip.jpg";

const AdvertCards = () => {
  return (
    <div class="container-fluid">
      <div class="row">
        <div
          class=" column   "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <img src={picA} />
          <img src={picB} />
          <img src={picC} />
          <img src={picF} />
        </div>
        <div
          class=" column "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <img src={picE2} />
          <img src={picG} />
          <img src={picE4} />
        </div>
        <div
          class="column  "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <img src={picE1} />
          <img src={picD} />
          <img src={picE3} />
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};
export default AdvertCards;
