import React from "react";
import logo from "../pics/logo.png";
function Footer() {
  return (
    <footer id="footer" className="footer-1">
      <div
        className="main-footer widgets-dark typo-light  "
        style={{ padding: "0px" }}
      >
        <div className="container-fluid">
          <div className="row  justify-content-center  land-card-footer ">
            <div className="col-xl-3  col-lg-12  col-xs-3 col-sm-12 col-md-12 d-flex justify-content-center ">
              <div className="widget no-box">
                <img src={logo} width={400} height={250} alt={""} />
              </div>
            </div>
            <div className="col-xl-3  col-lg-12  col-xs-3 col-sm-12 col-md-6 d-flex justify-content-center">
              <div className="widget no-box">
                <h5 className="widget-title text-center">
                  Useful Links
                  <span />
                </h5>
                <ul className="thumbnail-widget text-center">
                  <p>
                    <a className={"foot-links"} href="#pricing">
                      Pricing
                    </a>
                  </p>
                  <p>
                    <a className={"foot-links"} href="#contact">
                      Contact Us
                    </a>
                  </p>
                  <p>
                    <a className={"foot-links"} href="#about">
                      About
                    </a>
                  </p>
                  <p>
                    <a className={"foot-links"} href="#class">
                      Our Classes
                    </a>
                  </p>
                  <p>
                    <a className={"foot-links"} href="#services">
                      Our Services
                    </a>
                  </p>
                </ul>
              </div>
            </div>
            <div
              className=" col-xl-3  col-lg-12  col-xs-3 col-sm-12 col-md-12 d-flex justify-content-center"
              style={{ fontFamily: "serif" }}
            >
              <div className="widget no-box links" style={{ color: "#fdc92a" }}>
                <h5 className="widget-title text-center">
                  Contact Us
                  <span />
                </h5>
                <a
                  href="mailto:bodyflexgymsltd@gmail.com"
                  className=" col d-flex justify-content-center fas fa-envelope text-center"
                >
                  bodyflexgymsltd@gmail.com
                </a>

                <a
                  href={"tel:+254742060116"}
                  className="  col d-flex justify-content-center  fas fa-phone  text-center"
                  style={{ color: "#fc7b20" }}
                >
                  +254 710 520123
                </a>

                <br />
              </div>
            </div>
            <div className=" col-xl-3  col-lg-6  col-xs-3 col-sm-12 col-md-6 d-flex justify-content-center ">
              <div className="widget no-box">
                <h5 className="widget-title text-center">
                  Hours
                  <span />
                </h5>
                <ul className="hours text-center col-12">
                  <p className="hours col-12 text-center">
                    Monday: 5:00 AM - 8:00 PM
                  </p>
                  <p className="hours col-12 text-center ">
                    Tuesday: 5:00 AM - 8:00 PM
                  </p>
                  <p className="hours col-12 text-center">
                    Wednesday: 5:00 AM - 8:00 PM
                  </p>
                  <p className="hours col-12 text-center">
                    Thursday: 5:00 AM - 8:00 PM
                  </p>
                  <p className="hours col-12 text-center">
                    Friday: 5:00 AM - 8:00 PM
                  </p>
                  <p className="hours col-12 text-center">
                    Saturday: 6:00 AM - 6:00 PM
                  </p>
                  <p className="hours col-12 text-center">
                    Sunday: 2:00 PM - 6:00 PM
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <p className="copyright">
                © 2021 BODYFLEXGYMSLTD: All Rights Reserved.
              </p>
            </div>
            <div className="col-12 text-center hours">
              <a
                style={{ color: "#fc7b20" }}
                href={"mailto:hudsonshimanyula@gmail.com"}
              >
                Contact Designer
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
