import React from "react";
import About from "./About";
import AdvertCards from "./AdvertCards";
import Landing from "./Landing";
import Classes from "./Classes";
import Services from "./Services";
import Pricing from "./Pricing";
import Talk from "./Talk";

const Home = () => {
  return (
    <div>
      <Landing />
      <About />
      <AdvertCards />
      <Services />
      <Classes />
      <Pricing />
      <Talk />
    </div>
  );
};

export default Home;
