import React, { Component } from "react";
import logo from "../pics/logo.png";
import "./main.css";
import $ from "jquery";

export default class Navbar extends Component {
  componentDidMount() {
    var navbar = document.getElementById("navbar");

    $(window).scroll(function () {
      if (
        $(this).scrollTop() > 200 &&
        window.matchMedia("(min-width: 1000px").matches
      ) {
        this.navbar.style.height = "85px";
        this.navbar.style.backgroundColor = "black";
      }
    });
  }
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav id="navbar" class="navbar  fixed-top container-fluid">
        <a class="navbar-brand" href="#home">
          <div class="navbar-logo">
            <a href="#home">
              <img src={logo} alt="" id="logo" />
            </a>
          </div>
        </a>
        <div className="menu-icon" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul
          className={this.state.clicked ? "nav-menu active" : "nav-menu "}
          onClick={this.handleClick}
        >
          <li className="nav-item">
            <a className="nav-link" href="#home">
              HOME
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#service">
              OUR SERVICES
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#class">
              OUR CLASSES
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#pricing">
              PRICING
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">
              CONTACT US
            </a>
          </li>
          <li>
            <a
              href={
                "https://www.facebook.com/Bodyflex-Gyms-Limited-100694751282027"
              }
            >
              <i class="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href={"mailto:bodyflexgymsltd@gmail.com"}>
              <i class="fas fa-envelope"></i>
            </a>
          </li>
          <li>
            {" "}
            <a href={"tel:+254742060116"}>
              <i class="fas fa-phone-alt"></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
