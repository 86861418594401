import React from "react";
import picF from "../pics/zumba.jpg";
import pici from "../pics/boxing.jpg";

const Classes = () => {
  return (
    <div id="class" className=" class-sec container-fluid ">
      <div id="about" className="about-sec row-pad class-sec container-fluid">
        <div className="container">
          <div className="row bodytitles  justify-content-center ">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
              <h1 className="titles"> Our Classes</h1>
            </div>
          </div>
        </div>
        <div
          className="row  justify-content-center"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center text-center">
            <p className={"webtxt"}>
              {" "}
              Recieve lessons taught by the best in the business throughout the
              week. We offer insanity, circuit training , floor aerobics and
              step aerobics classes.
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <i class="fas fa-dumbbell"></i>
        </div>
      </div>

      <div className="row justify-content-center class-sec-img">
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center center-pad">
          <div className="col-12 justify-content-center  ">
            <h5 className=" card-title text-left">Zumba</h5>
            <p className="webtxt text-left">
              Come experience our fun and effective dance workout system to help
              you meet your weight loss goals.
            </p>
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center top-pad"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <img alt="" className="class-img img-fluid " src={picF} />
        </div>
      </div>
      <div className="row justify-content-center  ">
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center center-pad">
          <div className="col-12 justify-content-center  ">
            <h5 className="card-title text-left">Combat Fitness</h5>
            <p className="webtxt text-left">
              We offer muay Thai, boxing and kickboxing classes to help increase
              your strength, fitness and cardiovascular health.
            </p>
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center top-pad"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <img alt="" className="class-img img-fluid  " src={pici} />
        </div>
      </div>
    </div>
  );
};

export default Classes;
