import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init();
    window.addEventListener("load", AOS.refresh);
  });
  return (
    <div id="about" className="about-sec row-pad class-sec container-fluid">
      <div className="container">
        <div className="row bodytitles  justify-content-center ">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
            <h1 className="titles"> About Us</h1>
          </div>
        </div>
      </div>
      <div
        className="row  justify-content-center"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="800"
        data-aos-once="true"
      >
        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center text-center">
          <p className={"webtxt"}>
            {" "}
            Our goal is to provide our members with a quality fitness
            experience.We are passionate about health and exercise and pride
            ourselves on giving our clients.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <i class="fas fa-dumbbell"></i>
      </div>
    </div>
  );
};

export default About;
