import React from "react";

const Pricing = () => {
  return (
    <div id="pricing" className=" class-sec price-sec">
      <div className="row bodytitles  justify-content-center ">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center">
          <h1 className=" title-price text-center"> Pricing </h1>
        </div>
      </div>
      <br></br>

      <div className="row d-flex justify-content-center ">
        <div
          className="col-xl-4 col-lg-12  col-md-12  col-sm-12 col-xs-12 "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <div className="card">
            <div className="card-body">
              <h1 className="card-title">Daily Rates</h1>
              <div className="row  d-flex justify-content-center">
                <div className="col-12">
                  <h2 className="money "> $ Kshs 300</h2>
                </div>
              </div>
              <br />
              <div className="row  d-flex justify-content-center">
                <div className="col-6">
                  <h2 className="webtxt text-left">Full Access</h2>
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="orange"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                </div>
              </div>
              <div className="row  d-flex justify-content-center">
                <div className="col-6">
                  <h2 className="webtxt text-left">Bring Guests</h2>
                  <br />
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-xl-4 col-lg-12  col-md-12  col-sm-12 col-xs-12 "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <div className="card">
            <div className="card-body">
              <h1 className="card-title">Monthly Rates</h1>
              <div className="row  d-flex justify-content-center">
                <div className="col-12">
                  <h2 className="money ">1 Month @ $ Kshs 2,500 </h2>
                  <h2 className="money  ">(Student @ Kshs 2,000)</h2>{" "}
                  <h2 className="money "> 3 Months @ $ Kshs 8,000</h2>
                  <h2 className="money "> 6 Months @ $ Kshs 16,500</h2>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-6">
                  <h2 className="webtxt text-left"> Full Access</h2>
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="orange"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <h2 className="webtxt text-left"> Bring Guests</h2>
                  <h2
                    className=" webtxt text-left"
                    style={{ color: "#fc7b20", fontSize: "0.9rem" }}
                  >
                    (Three Sesssions)
                  </h2>
                  <br />
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="orange"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                </div>
              </div>
              <p className="card-text"></p>
            </div>
          </div>
        </div>

        <div
          className="col-xl-4 col-lg-12  col-md-12  col-sm-12 col-xs-12  "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <div className="card">
            <div className="card-body">
              <h1 className="card-title">Annual Rates</h1>
              <div className="row  d-flex justify-content-center">
                <div className="col-12">
                  <h2 className="money"> $ Kshs 30,000</h2>
                </div>
              </div>
              <br></br>
              <div className="row  d-flex justify-content-center">
                <div className="col-6">
                  <h2 className="webtxt text-left"> Full Access</h2>
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="orange"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                </div>
              </div>
              <div className="row  d-flex justify-content-center">
                <div className="col-6">
                  <h2 className="webtxt text-left">Bring Guests </h2>
                  <h2
                    className=" webtxt text-left"
                    style={{ color: "#fc7b20", fontSize: "0.9rem" }}
                  >
                    (Ten Sesssions)
                  </h2>
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="orange"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                </div>
              </div>
              <div className="row  d-flex justify-content-center">
                <div className="col-6">
                  <h2 className="webtxt text-left">Recieve Gym Merchandise</h2>
                </div>
                <div className="col-6">
                  <svg
                    viewBox="0 0 16 16"
                    className=" row bi bi-check"
                    fill="orange"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
