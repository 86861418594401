import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import JoinContact from "./JoinContact";
const Join = (props) => {
  return (
    <Modal
      {...props}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-modal"
      class="modal"
    >
      <Modal.Header
        closeButton
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Modal.Title
          className=" text-center modal-titles"
          id="contained-modal-title-vcenter"
          style={{
            color: "rgba(252, 123, 32, 255)",
            fontWeight: "bold",
            fontFamily: "Playfair Display",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-center"> Join Us Today</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <JoinContact />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
export default Join;
