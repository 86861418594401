import React, { Component } from "react";
import { firebase } from "@firebase/app";
import Form from "react-bootstrap/Form";

export default class JoinContact extends Component {
  componentDidMount() {
    const form = document.querySelector("#join-us");
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      firebase
        .firestore()
        .collection("joins")
        .add({
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          email: form.email.value,
          phone: form.phone.value,
        })
        .then(function () {
          alert("Information submitted Successfully");
          form.firstName.value = "";
          form.lastName.value = "";
          form.email.value = "";
          form.phone.value = "";
        })
        .catch(function (error) {
          alert(error);
          alert(
            "There was an issue submitting the information. call as at +254 742 060116 to talk to us directly"
          );
        });
    });
  }
  render() {
    return (
      <div className="wrapper">
        <div className="form-wrapper">
          <form id={"join-us"}>
            <div className="firstName">
              <label className="form-text" htmlFor="firstName">
                First Name
              </label>
              <input
                className={""}
                type="text"
                name="firstName"
                noValidate
                required={"yes"}
                minLength={3}
                maxLength={10}
              />
            </div>
            <div className="lastName">
              <label className="form-text" htmlFor="lastName">
                Last Name
              </label>
              <input
                className={""}
                type="text"
                name="lastName"
                noValidate
                required={"yes"}
                minLength={3}
                maxLength={10}
              />
            </div>
            <div className="email">
              <label className="form-text" htmlFor="email">
                Email
              </label>
              <input
                className={""}
                type="email"
                noValidate
                name="email"
                required={"yes"}
                minLength={3}
              />
            </div>
            <div className="email">
              <label className="form-text" htmlFor="tel">
                Phone
              </label>
              <input className={""} type="tel" required name="phone" />
            </div>

            <div className="submit-but">
              <button href="Quote" className="btn btn-info">
                JOIN US{" "}
              </button>
              <Form.Row>
                <a
                  href={
                    "https://www.facebook.com/Bodyflex-Gyms-Limited-100694751282027"
                  }
                >
                  <i class="fab fa-facebook"></i>
                </a>
                <a href={"mailto:bodyflexgymsltd@gmail.com"}>
                  <i class="fas fa-envelope"></i>
                </a>
                <a href={"tel:+254742060116"}>
                  <i class="fas fa-phone-alt"></i>
                </a>
              </Form.Row>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
