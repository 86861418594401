import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Join from "./Join";
import picA from "../pics/room.jpeg";

const Landing = () => {
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="" id="home">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <img className="land-img  " src={picA} />
        </div>
      </div>
      <div class="centered">
        <h1
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2500"
          data-aos-once="true"
          className="webtxt text-center"
          style={{
            color: "rgba(252, 123, 32, 255)",
            fontWeight: "bold",
            fontFamily: "Playfair Display",
          }}
        >
          BODYFLEX GYMS KAKAMEGA
        </h1>
        <h2
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2500"
          data-aos-once="true"
          className="webtxt text-center"
          style={{ color: "#fdc92a", fontWeight: "bold" }}
        >
          Challenging Your Limits !!
        </h2>
        <p
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="2500"
          data-aos-once="true"
          className=" row webtxt text-pad"
          style={{ color: "#fdc92a", fontWeight: "bold", padding: "15px" }}
        >
          {" "}
          Bodyflex will help you achieve your goals and go beyond your limits.
          We provide quality equipment and a good environment for a happier and
          healthier you!
        </p>
        <div class="row justify-content-center">
          <i class="fas fa-dumbbell"></i>
        </div>
        <br></br>
        <div
          className="col-xl-12 col-lg-12  col-md-12  col-sm-12 col-xs-12  d-flex justify-content-center "
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
          data-aos-once="true"
        >
          <button
            className="btn btn-info-l"
            onClick={() => setModalShow(true)}
          ></button>
          <button
            type="button"
            class="btn btn-outline-primary "
            style={{
              color: "#fdc92a",
              fontWeight: "bold",
            }}
            onClick={() => setModalShow(true)}
          >
            JOIN THE BEST GYM TODAY{" "}
          </button>
        </div>
      </div>

      <Join show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
export default Landing;
