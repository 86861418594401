import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import firebase from "firebase/app";
import "firebase/firestore";
import * as serviceWorker from "./serviceWorker";
const firebaseConfig = {
  apiKey: "AIzaSyACpEkYCa2RpjPHT16A2DdY3uFbkJP-xII",
  authDomain: "bodyflexgyms.firebaseapp.com",
  databaseURL: "https://bodyflexgyms.firebaseio.com",
  projectId: "bodyflexgyms",
  storageBucket: "bodyflexgyms.appspot.com",
  messagingSenderId: "929155880900",
  appId: "1:929155880900:web:90d001fcd2e6c44f3f3691",
  measurementId: "G-Q75MNDYJ1V",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
